$(document).ready(function() {
	function animText() {
		var titles = $('.anim-text');
		var titlesArr = jQuery.makeArray(titles);
		var offsets = [];
		for (var i = 0; i < titlesArr.length; i++) {
			var element = titlesArr[i];
			var elementOffset = $(element).offset().top;
			offsets.push(elementOffset);
		}

		$(window).on('scroll', function() {
			var scrollTop = $(window).scrollTop();
			for (var i = 0; i < offsets.length; i++) {
				var diff = offsets[i] - scrollTop;
				if (diff < 700) {
					$(titlesArr[i]).addClass('anim-text_start');
				}
			}
		});
	}

	if ($(window).width() >= 998) {
		animText();
	}

	if ($(window).width() < 998) {
		$('.hp-button__item').attr('data-aos-offset', 100);
	}

});